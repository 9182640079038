import {MC} from './MC.js';

const MCCache = {
  cacheMap: {},
  put: function(url, object) {
    if (MC.isNull(url)) {
      MC.error("Can not put object into cache without url key!");
    } else {
      this.cacheMap[url] = object;
    }
  },
  get: function(url) {
    if (this.cacheMap[url] == undefined || this.cacheMap[url] == null) {
      return null;
    } else {
      return this.cacheMap[url];
    }
  },
  clear: function() {
    this.cacheMap = {};
  }
};

export {MCCache};