import React from "react";

import {MC} from './MC.js';
import {Modal} from "./Modal.jsx";

class Message extends React.Component {

  close = () => {
    if (MC.isFunction(this.props.data.onClose)) {
      this.props.data.onClose();
    }
  };

  render() {
    let data = this.props.data;
    if (data) {
      let buttons = null;
      if (Array.isArray(data.buttons) && data.buttons.length > 0) {
        buttons = [];
        data.buttons.forEach((button, i) => {
          let icon = null;
          if (button.icon) {
            icon = <i className={button.icon}></i>;
          }
          buttons.push(
            <div key={"button" + i} className={"ui button "+ (button.class ? button.class : "")}  onClick={button.action}>
              {icon}
              {button.title}
            </div>
          );
        });
        buttons = <div className="actions">{buttons}</div>
      }
      return (
        <Modal show={true} onClose={this.close} cssClass={data.size} header={data.heading}>
          {buttons}
        </Modal>
      );
    } else {
      return null;
    }
  }

}

export {Message};