import React from "react";

class Modal extends React.Component {

  onClose = () => {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
    document.querySelector('body').classList.remove('showing-modal');
  };

  componentDidMount() {
    this.fixBody();
  }

  componentDidUpdate() {
    this.fixBody();
  }

  componentWillUnmount() {
    document.querySelector('body').classList.remove('showing-modal');
  }

  fixBody() {
    if (this.props.show) {
      document.querySelector('body').classList.add('showing-modal');
    }
  }

  render() {
    if (!this.props.show) {
      return null;
    }
    var className = (this.props.cssClass ? this.props.cssClass + ' ' : '') + 'mnc-modal';
    var header = null;
    if (this.props.header) {
      header = <div className="header">{this.props.header}</div>;
    }
    return (
      <div className="mnc-backdrop">
        <div className={className}>
          <i className="close icon" onClick={this.onClose}></i>
          {header}
          <div className="content">{this.props.children}</div>
        </div>
      </div>
    );
  }

}

export {Modal};