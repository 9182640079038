import {MC} from "./MC.js";

/** 
 * The syntax for the formatting is:
 * 0 = Digit
 * # = Digit, zero shows as absent
 * . = Decimal separator
 * - = Negative sign
 * , = Grouping Separator
 * % = Percent (multiplies the number by 100)
 * 
 * For example, a format of "#,###.00" and text of 4500.20 will
 * display as "4.500,20" with a locale of "de", and "4,500.20" with a locale of "us"
 **/
class NumberFormat {

  nfLocales = {};
  nfLocalesLikeUS = ['ae', 'au', 'ca', 'cn', 'eg', 'gb', 'hk', 'il', 'in', 'jp', 'sk', 'th', 'tw', 'us'];
  nfLocalesLikeDE = ['at', 'br', 'de', 'dk', 'es', 'gr', 'it', 'nl', 'pt', 'tr', 'vn'];
  nfLocalesLikeFR = ['bg', 'cz', 'cs', 'fi', 'fr', 'no', 'pl', 'ru', 'se', 'sk'];
  nfLocalesLikeCH = ['ch'];
  nfLocaleFormatting = [[".", ","], [",", "."], [",", " "], [".", "'"]];
  nfAllLocales = [this.nfLocalesLikeUS, this.nfLocalesLikeDE, this.nfLocalesLikeFR, this.nfLocalesLikeCH];
  parseNumberDefaults = {
    locale: "us",
    decimalSeparatorAlwaysShown: false,
    isPercentage: false,            // treats the input as a percentage (i.e. input divided by 100)
    autoDetectPercentage: true,     // will search if the input string ends with '%', if it does then the above option is implicitly set
    isFullLocale: false,
    strict: false,                  // will abort the parse if it hits any unknown char
    overrideGroupSep: null,         // override for group separator
    overrideDecSep: null,           // override for decimal point separator
    overrideNegSign: null,          // override for negative sign
    allowPostfix: false             // will truncate the input string as soon as it hits an unknown char
  };
  formatNumberDefaults = {
    format: "#,###.00",
    locale: "us",
    decimalSeparatorAlwaysShown: false,
    nanForceZero: true,
    round: true,
    isFullLocale: false,
    overrideGroupSep: null,
    overrideDecSep: null,
    overrideNegSign: null,
    isPercentage: false,            // treats the input as a percentage (i.e. input multiplied by 100)
    autoDetectPercentage: true      // will search if the format string ends with '%', if it does then the above option is implicitly set
  };

  init() {
    for (var gi = 0; gi < this.nfAllLocales.length; gi++) {
      var localeGroup = this.nfAllLocales[gi];
      for (var i = 0; i < localeGroup.length; i++) {
        this.nfLocales[localeGroup[i]] = gi;
      }
    }
  };

  formatCodes(locale, isFullLocale) {
    if (MC.isEmptyObject(this.nfLocales)) {
      this.init();
    }
    var dec = ".";
    var group = ",";
    var neg = "-";
    if (isFullLocale == false) {
      if (locale.indexOf('_') != -1)
        locale = locale.split('_')[1].toLowerCase();
      else if (locale.indexOf('-') != -1)
        locale = locale.split('-')[1].toLowerCase();
    }
    var codesIndex = this.nfLocales[locale];
    if (codesIndex) {
      var codes = this.nfLocaleFormatting[codesIndex];
      if (codes) {
        dec = codes[0];
        group = codes[1];
      }
    }
    return {dec, group, neg};
  };

  formatNumber(numberString, options) {
    var options = MC.extend({}, this.formatNumberDefaults, options);
    var formatData = this.formatCodes(options.locale.toLowerCase(), options.isFullLocale);
    var dec = formatData.dec;
    var group = formatData.group;
    var neg = formatData.neg;
    var validFormat = "0#-,.";
    // strip all the invalid characters at the beginning and the end
    // of the format, and we'll stick them back on at the end
    // make a special case for the negative sign "-" though, so 
    // we can have formats like -$23.32
    var prefix = "";
    var negativeInFront = false;
    for (var i = 0; i < options.format.length; i++) {
      if (validFormat.indexOf(options.format.charAt(i)) == -1)
        prefix = prefix + options.format.charAt(i);
      else
        if (i == 0 && options.format.charAt(i) == '-') {
          negativeInFront = true;
          continue;
        } else
          break;
    }
    var suffix = "";
    for (var i = options.format.length - 1; i >= 0; i--) {
      if (validFormat.indexOf(options.format.charAt(i)) == -1)
        suffix = options.format.charAt(i) + suffix;
      else
        break;
    }
    options.format = options.format.substring(prefix.length);
    options.format = options.format.substring(0, options.format.length - suffix.length);
    // now we need to convert it into a number
    //while (numberString.indexOf(group) > -1) 
    //  numberString = numberString.replace(group, '');
    //var number = new Number(numberString.replace(dec, ".").replace(neg, "-"));
    var number = new Number(numberString);
    return this._formatNumber(number, options, suffix, prefix, negativeInFront);
  };

  _formatNumber(number, options, suffix, prefix, negativeInFront) {
    var options = MC.extend({}, this.formatNumberDefaults, options);
    var formatData = this.formatCodes(options.locale.toLowerCase(), options.isFullLocale);
    var dec = formatData.dec;
    var group = formatData.group;
    var neg = formatData.neg;
    // check overrides
    if (options.overrideGroupSep != null) {
      group = options.overrideGroupSep;
    }
    if (options.overrideDecSep != null) {
      dec = options.overrideDecSep;
    }
    if (options.overrideNegSign != null) {
      neg = options.overrideNegSign;
    }
    // Check NAN handling
    var forcedToZero = false;
    if (isNaN(number)) {
      if (options.nanForceZero == true) {
        number = 0;
        forcedToZero = true;
      } else {
        return '';
      }
    }
    // special case for percentages
    if (options.isPercentage == true || (options.autoDetectPercentage && suffix.charAt(suffix.length - 1) == '%')) {
      number = number * 100;
    }
    var returnString = "";
    if (options.format.indexOf(".") > -1) {
      var decimalPortion = dec;
      var decimalFormat = options.format.substring(options.format.lastIndexOf(".") + 1);
      // round or truncate number as needed
      if (options.round == true)
        number = new Number(number.toFixed(decimalFormat.length));
      else {
        var numStr = number.toString();
        if (numStr.lastIndexOf('.') > 0) {
          numStr = numStr.substring(0, numStr.lastIndexOf('.') + decimalFormat.length + 1);
        }
        number = new Number(numStr);
      }
      var decimalValue = new Number(number.toString().substring(number.toString().indexOf('.')));
      var decimalString = new String(decimalValue.toFixed(decimalFormat.length));
      decimalString = decimalString.substring(decimalString.lastIndexOf('.') + 1);
      for (var i = 0; i < decimalFormat.length; i++) {
        if (decimalFormat.charAt(i) == '#' && decimalString.charAt(i) != '0') {
          decimalPortion += decimalString.charAt(i);
          continue;
        } else if (decimalFormat.charAt(i) == '#' && decimalString.charAt(i) == '0') {
          var notParsed = decimalString.substring(i);
          if (notParsed.match('[1-9]')) {
            decimalPortion += decimalString.charAt(i);
            continue;
          } else
            break;
        } else if (decimalFormat.charAt(i) == "0")
          decimalPortion += decimalString.charAt(i);
      }
      returnString += decimalPortion
    } else
      number = Math.round(number);
    var ones = Math.floor(number);
    if (number < 0)
      ones = Math.ceil(number);
    var onesFormat = "";
    if (options.format.indexOf(".") == -1)
      onesFormat = options.format;
    else
      onesFormat = options.format.substring(0, options.format.indexOf("."));
    var onePortion = "";
    if (!(ones == 0 && onesFormat.substr(onesFormat.length - 1) == '#') || forcedToZero) {
      // find how many digits are in the group
      var oneText = new String(Math.abs(ones));
      var groupLength = 9999;
      if (onesFormat.lastIndexOf(",") != -1)
        groupLength = onesFormat.length - onesFormat.lastIndexOf(",") - 1;
      var groupCount = 0;
      for (var i = oneText.length - 1; i > -1; i--) {
        onePortion = oneText.charAt(i) + onePortion;
        groupCount++;
        if (groupCount == groupLength && i != 0) {
          onePortion = group + onePortion;
          groupCount = 0;
        }
      }
      // account for any pre-data padding
      if (onesFormat.length > onePortion.length) {
        var padStart = onesFormat.indexOf('0');
        if (padStart != -1) {
          var padLen = onesFormat.length - padStart;

          // pad to left with 0's or group char
          var pos = onesFormat.length - onePortion.length - 1;
          while (onePortion.length < padLen) {
            var padChar = onesFormat.charAt(pos);
            // replace with real group char if needed
            if (padChar == ',')
              padChar = group;
            onePortion = padChar + onePortion;
            pos--;
          }
        }
      }
    }
    if (!onePortion && onesFormat.indexOf('0', onesFormat.length - 1) !== -1)
      onePortion = '0';
    returnString = onePortion + returnString;
    // handle special case where negative is in front of the invalid characters
    if (number < 0 && negativeInFront && prefix.length > 0)
      prefix = neg + prefix;
    else if (number < 0)
      returnString = neg + returnString;
    if (!options.decimalSeparatorAlwaysShown) {
      if (returnString.lastIndexOf(dec) == returnString.length - 1) {
        returnString = returnString.substring(0, returnString.length - 1);
      }
    }
    returnString = prefix + returnString + suffix;
    return returnString;
  };

  parseNumber(numberString, options) {
    var options = MC.extend({}, this.parseNumberDefaults, options);
    var formatData = this.formatCodes(options.locale.toLowerCase(), options.isFullLocale);
    var dec = formatData.dec;
    var group = formatData.group;
    var neg = formatData.neg;
    // check overrides
    if (options.overrideGroupSep != null) {
      group = options.overrideGroupSep;
    }
    if (options.overrideDecSep != null) {
      dec = options.overrideDecSep;
    }
    if (options.overrideNegSign != null) {
      neg = options.overrideNegSign;
    }
    var valid = "1234567890";
    var validOnce = '.-';
    var strictMode = options.strict;
    // now we need to convert it into a number
    while (numberString.indexOf(group) > -1) {
      numberString = numberString.replace(group, '');
    }
    numberString = numberString.replace(dec, '.').replace(neg, '-');
    var validText = '';
    var hasPercent = false;
    if (options.isPercentage == true || (options.autoDetectPercentage && numberString.charAt(numberString.length - 1) == '%')) {
      hasPercent = true;
    }
    for (var i = 0; i < numberString.length; i++) {
      if (valid.indexOf(numberString.charAt(i)) > -1) {
        validText = validText + numberString.charAt(i);
      } else if (validOnce.indexOf(numberString.charAt(i)) > -1) {
        validText = validText + numberString.charAt(i);
        validOnce = validOnce.replace(numberString.charAt(i), '');
      } else {
        if (options.allowPostfix) {
          // treat anything after this point (inclusive) as a postfix
          break;
        } else if (strictMode) {
          // abort and force the text to NaN as it's not strictly valid
          validText = 'NaN';
          break;
        }
      }
    }
    var number = new Number(validText);
    if (hasPercent) {
      number = number / 100;
      var decimalPos = validText.indexOf('.');
      if (decimalPos != -1) {
        var decimalPoints = validText.length - decimalPos - 1;
        number = number.toFixed(decimalPoints + 2);
      } else {
        number = number.toFixed(2);
      }
    }
    return number;
  };

}

export {NumberFormat};